import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const DEFAULT_TITLE = 'あっかんべーのメモ書き'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'あっかんべーのメモ書き' }
  },
  {
    path: '/ix',
    name: 'ix',
  },
  {
    path: '/tools',
    name: 'tools',
    redirect: '/',
    children: [
      {
        path: 'tool1',
        name: 'tool1',
      },
      {
        path: 'tool2',
        name: 'tool2',
      }
    ]
  },
  {
    path: '/personal',
    name: 'personal',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  let title = to.meta.title
  if(title == null) title = DEFAULT_TITLE
  document.title = title
})

export default router
