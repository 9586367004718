import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "twitter-timeline",
  "data-lang": "ja",
  "data-width": "600",
  "data-height": "400",
  href: "https://twitter.com/kanbenshiteyo25?ref_src=twsrc%5Etfw"
};
import { onMounted } from 'vue'; // Twitterタイムラインを表示

export default {
  __name: 'TwitterTimeline',

  setup(__props) {
    onMounted(() => {
      setTimeout(() => {
        let tweetScript = document.createElement('script');
        tweetScript.setAttribute('src', 'https://platform.twitter.com/widgets.js');
        tweetScript.setAttribute('async', 'true');
        document.head.appendChild(tweetScript);
      }, 200);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("a", _hoisted_1, "Tweets by kanbenshiteyo25");
    };
  }

};